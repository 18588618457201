import * as Sentry from "@sentry/nuxt";

const {
  public: { sentryDsn, appEnv, releaseVersion, sentryEnabled },
} = useRuntimeConfig();

if (sentryEnabled.toString() === "true") {
  Sentry.init({
    dsn: sentryDsn,
    environment: appEnv + "_client",
    release: releaseVersion,
  });
}
